<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'" :fill="color">
        <g xmlns="http://www.w3.org/2000/svg" id="Layer_8">
            <g>
                <g>
                    <circle class="st0" cx="125" cy="125" r="124.5"/>
                </g>
                <g>
                    <g>
                        <path class="st1" d="M175.6,81.9c-6.2-6.8-14.8-10.5-24.1-10.5c-6.9,0-13.3,2.2-18.9,6.5c-2.8,2.2-5.4,4.9-7.6,8      c-2.3-3.1-4.8-5.8-7.6-8c-5.6-4.3-12-6.5-18.9-6.5c-9.3,0-17.8,3.7-24.1,10.5c-6.2,6.7-9.5,15.8-9.5,25.7      c0,10.2,3.8,19.5,11.9,29.3c7.3,8.8,17.8,17.7,29.9,28c4.1,3.5,8.8,7.5,13.7,11.8c1.3,1.1,2.9,1.7,4.7,1.7      c1.7,0,3.4-0.6,4.7-1.7c4.9-4.3,9.6-8.3,13.7-11.8c12.1-10.3,22.6-19.3,29.9-28c8.1-9.8,11.9-19.1,11.9-29.3      C185.2,97.7,181.8,88.6,175.6,81.9z"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'WishList',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
    .st0{fill:#FFECF2;}
	.st1{fill:#F44785;}
</style>